import React from "react";
import style from "./Option.module.css";
function Option({ data }) {
  const { name, options } = data;

  return (
    <div className={style.option}>
      <select name={name} id={name}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Option;
