import React from "react";
import style from "./Hero.module.css";
function Hero({ data }) {
  const { image } = data;
  return (
    <div className={style.hero}>
      <img src={image} alt="Hero illurstration" />
    </div>
  );
}

export default Hero;
