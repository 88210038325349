import React from "react";
import style from "./Cards.module.css";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { Config } from "../Config";
import axios from "axios";
function Cards({ data, token }) {
  const { cards } = data;
  const [IframeModal, setIframeModal] = useState(false);
  const [newdata, setNewdata] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const playGames = async () => {
          try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const resp = await axios.get(
          `${Config.url.API_URL}/channel/partner/play/08164529602/game/1/`,
          config
        );
        // console.log(resp.data, "games");
        setNewdata(resp.data);
      } catch (err) {
        // Handle Error Here
        // console.error(err);
      }
    };
    playGames();
  }, [token]);

  const onCloseModal = () => {
    setIframeModal(false);
  };
  return (
    <div className={style.cards}>
      {cards?.map((card, index) => (
        <>
          <div
           
          >
            <div className={style.card} key={index}>
              <img src={card.image} alt="" />
            <Button  onClick={() => {
              setIframeModal(true);
            }}>Play Game</Button>
            </div>
          </div>

          <Modal show={IframeModal} fullscreen onHide={onCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Game</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Iframe
                url={newdata?.data}
                width="90%"
                id="myId" 
                height="90%"
              />
            </Modal.Body>
          </Modal>
        </>
      ))}
    </div>
  );
}

export default Cards;
