import React, { useState} from "react";
import style from "./Header.module.css";
import { ReactComponent as Hamburger} from '../../images/hamburger.svg'
import { Offcanvas} from 'react-bootstrap'
function Header({ data }) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { logo, links } = data;
  return (<>
  <div className={style.TopHeader}>

    <div className={style.header}>
      <div className={style.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={style.links}>
        {links.map((link, index) => (
          <a href={link.url} className={style.link} key={index}>
            {link.name}
          </a>
        ))}
      </div>
    </div>
      <div onClick={handleShow} className={style.hamburger}>
      <Hamburger/>
      </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            
          <div className={style.logo}>
        <img src={logo} alt="" />
      </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
     
          <ul style={{paddingLeft: "0px", cursor: "pointer"}}>
            <li href="/#suscribe">
            SUSCRIBE
            </li>
            <li>
            COMPETITION
            </li>
            <li>
            FAQ 
            </li>
          </ul>
          


        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
