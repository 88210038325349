import "./App.css";
import Landing from "./pages/landing/Landing";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Game from "./pages/game/Game";
import TestApi from "./pages/testapi/TestApi";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          {/* <Route exact path="/:id" element={<Game />} /> */}
          <Route exact path="/testapi" element={<TestApi />} />
        </Routes>
      </Router>

      {/* var req = new XMLHttpRequest();
        req.open('GET', document.location, false);
        req.send(null);
        var headers = req.getAllResponseHeaders();
        var arr = headers.trim().split(/[\r\n]+/);
        var headerMap = {};
        // console.log(headerMap);
        arr.forEach(function (line) {
            var parts = line.split(': ');
            var header = parts.shift();
            var value = parts.join(': ');
            headerMap[header] = value;
        });
        if (headerMap.msisdn) {
            // console.log(headerMap.msisdn);
            $('#telephone').val(headerMap.msisdn);
            checkStatus(headerMap.msisdn);
        } */}
    </div>
  );
}

export default App;
