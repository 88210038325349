import React from 'react'
import Cards from '../Cards/Cards'
import { Config } from '../Config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import CustomSpinner from '../CustomSpinner/CustomSpinner'

const CardContainer = ({ data }) => {

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState({});

    useEffect(() => {
        // simulate login with given parameter to get access token
        const params = {
            access_key: "jRtQ7-rmaFt-vWZCZ-mtyWR",
            secret_key: "CB*D$@ST485"
        }
        setLoading(true)


        const getLogin = async () => {
            try {
                const resp = await axios.post(`${Config.url.API_URL}/auth/login`, params)
                setToken(resp.data);
                sessionStorage.setItem("m9Auth", JSON.stringify(resp.data))
                setLoading(false)
                // console.error(token.data, "token");

            } catch (err) {
                // Handle Error Here
                // console.error(err, "login");
            }
        };
        getLogin();

    }, [])

    return (
        <>
        <div>
            <Cards data={data} token={token?.data?.access_token} />
        </div>
        {/* {loading === true ? <CustomSpinner/>: (

        )
        } */}
        </>
    )
}

export default CardContainer