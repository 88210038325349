import React from "react";
import style from "./Subscription.module.css";
function Subscription({ data }) {
  const { title, onSwitch, offSwitch, plans } = data;
  return (
    <section id="suscribe">
    <div className={style.subscription}>
      <h2 className="title">{title}</h2>
      <div className={style.selectPlan}>
        <p>{onSwitch}</p>
        <label className={style.switch}>
          <input type="checkbox" />
          <span class={style.slider}></span>
        </label>
        <p>{offSwitch}</p>
      </div>

      <div className={style.plans}>
        {plans.map((plan, index) => (
          <div className={style.plan} key={index}>
            <h3 className={style.planTitle}> {plan.title}</h3>
            <div className={style.planSummary}>
              <h2 className={style.price}> {plan.price}</h2>
            </div>
            <button className={style.submit}> SELECT</button>
          </div>
        ))}
      </div>
    </div>
    </section>  );
}

export default Subscription;
