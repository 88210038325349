import React from "react";
import TestAPI from "../../components/TestApi/TestApi";
function TestApi() {
  const testData = {
    title: "Test API",
    summary: "Test API is a test API for testing purpose",
    cards: [{}],
  };
  return (
    <div>
      <TestAPI data={testData} />
    </div>
  );
}

export default TestApi;
