import React, { useState } from "react";
import axios from "axios";
import { cards } from "../../data";
import Card from "./Card/Card";
import Input from "./Input/Input";
import Option from "./Option/Option";
import Response from "./Response/Response";
import style from "./TestApi.module.css";
function TestApi({ data }) {
  const { title, summary, cards } = data;
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [bearerToken, setBearerToken] = useState("");
  const [loginResponse, setLoginResponse] = useState("");
  const [catalogueResponse, setCatalogueResponse] = useState("");
  const [userPassport, setUserPassport] = useState("");
  const [action, setAction] = useState("subscribe");
  const [subscribeResponse, setSubscribeResponse] = useState("");
  const [gameId, setGameId] = useState(1);
  const [playGameResponse, setPlayGameResponse] = useState("");

  const documentation = {
    login: [
      {
        parameter: "access_token",
        value: "string",
        type: "String",
        description: "This can be gotten from your dashboard after logging in",
      },
      {
        parameter: "secret_key",
        value: "string",
        type: "String",
        description: "This can be gotten from your dashboard after logging in",
      },
    ],
    catalogue: [
      {
        parameter: "bearer_token",
        value: "String",
        type: "Authorization",
        description:
          "This is located in your dashboard. Place this in the Header",
      },
    ],
    subscribe: [
      {
        parameter: "bearer_token",
        value: "String",
        type: "Authorization",
        description:
          "This is located in your dashboard. Place this in the Header",
      },
      {
        parameter: "userPassport",
        value: "String",
        type: "Parameter",
        description: "Your prefered email or phone number",
      },
      {
        parameter: "userPassport",
        value: "subscribe or unsubscribe ",
        type: "Parameter",
        description: "Subscribe or Unsubscribe",
      },
    ],
    play: [
      {
        parameter: "bearer_token",
        value: "String",
        type: "Authorization",
        description:
          "This is located in your dashboard. Place this in the Header",
      },
      {
        parameter: "userPassport",
        value: "subscribe or unsubscribe ",
        type: "Parameter",
        description: "Subscribe or Unsubscribe",
      },
      {
        parameter: "gameId",
        value: "Number",
        type: "Parameter",
        description: "The game Id is located on the catalogue",
      },
    ],
  };

  const login = {
    title: "Login Api",
    type: "Post",
    input: [
      { label: "Your Access token", value: apiKey, setter: setApiKey },
      { label: "Your secret key", value: secretKey, setter: setSecretKey },
    ],
    documentation: documentation.login,
    submit: (e) => {
      if (apiKey !== "" && secretKey !== "") {
        loginApi(
          "https://litelab.9ijakids.com/api/v2/auth/login",
          "post",
          { access_key: apiKey, secret_key: secretKey },
          setLoginResponse
        );
      } else {
        alert("Api key or secret key is empty");
      }
    },
    response: loginResponse,
  };

  const catalogue = {
    title: "Catalogue API",
    type: "Get",
    input: [
      {
        label: "Your Bearer Token",
        value: bearerToken,
        setter: setBearerToken,
      },
    ],
    documentation: documentation.catalogue,
    submit: (e) => {
      if (bearerToken !== "") {
        postApi(
          "https://litelab.9ijakids.com/api/v2/channel/partner/catalog",
          "get",
          {},
          setCatalogueResponse,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } else {
        alert("Bearer token is empty");
      }
    },
    response: catalogueResponse,
  };
  const subscribe = {
    title: "Subscribe Api",
    type: "Post",
    input: [
      {
        label: "Your Bearer Token",
        value: bearerToken,
        setter: setBearerToken,
      },
      {
        label: "Email or phone number",
        value: userPassport,
        setter: setUserPassport,
      },
      {
        label: "Action",
        value: action,
        setter: setAction,
      },
    ],
    documentation: documentation.subscribe,
    submit: (e) => {
      if (bearerToken !== "" && action !== "" && userPassport !== "") {
        subscribeApi(
          "https://litelab.9ijakids.com/api/v2/channel/partner/subs",
          "post",
          { userPassport: userPassport, action },
          setSubscribeResponse,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } else {
        alert("Bearer token or Email/phone is empty");
      }
    },
    response: subscribeResponse,
  };
  const playGame = {
    title: "PlayGame Api",
    type: "Get",
    input: [
      {
        label: "Your Bearer Token",
        value: bearerToken,
        setter: setBearerToken,
      },
      {
        label: "Email or phone number",
        value: userPassport,
        setter: setUserPassport,
      },
      {
        label: "Game Id",
        value: gameId,
        setter: setGameId,
      },
    ],
    documentation: documentation.play,
    submit: (e) => {
      // console.log(bearerToken, "bt", gameId, userPassport);
      if (bearerToken !== "" && gameId !== "" && userPassport !== "") {
        playGameApi(
          `https://litelab.9ijakids.com/api/v2/channel/partner/play?userPassport=${userPassport}&gameId=${gameId}`,
          "get",
          { userPassport: userPassport, gameId: gameId },
          setPlayGameResponse,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } else {
        alert("Bearer token or Email/phone is empty");
      }
    },
    response: playGameResponse,
  };

  async function postApi(url, type, data, setData, header) {
    const result = await axios[type](url, header, data)
      .then((data) => setData(data))
      .catch((err) => {
        setData(err);
      });
  }
  async function subscribeApi(url, type, data, setData, header) {
    const result = await axios[type](url, data, header)
      .then((data) => setData(data))
      .catch((err) => {
        setData(err);
      });
  }
  async function playGameApi(url, type, data, setData, header) {
    const result = await axios[type](url, header, data)
      .then((data) => setData(data))
      .catch((err) => {
        setData(err);
      });
  }
  async function loginApi(url, type, data, setData) {
    const result = await axios[type](url, data)
      .then((data) => setData(data))
      .catch((err) => {
        setData(err);
      });
  }

  return (
    <div className={style.testApi}>
      <div className={style.header}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.summary}>{summary}</p>
      </div>

      <div className={style.cards}>
        <Option data={login} />
        <Option data={catalogue} />
        <Option data={subscribe} />
        <Option data={playGame} />
      </div>
    </div>
  );
}

export default TestApi;
