import React from "react";
import Option from "../Option/Option";
import style from "./Events.module.css";
function Events({ data }) {
  const { title, options } = data;
  return (
    <div className={style.events}>
      <h2 className="title">{title} </h2>

      <div className={style.options}>
        {options.map((option, index) => (
          <Option key={index} data={option} />
        ))}
      </div>
    </div>
  );
}

export default Events;
