import React from "react";
import style from "./Input.module.css";
function Input({ label, setValue, value }) {
  return (
    <div className={style.input}>
      <label className={style.label}> {label}</label>
      <input
        type="text"
        className={style.textInput}
        onInput={(e) => setValue(e.currentTarget.value)}
      />
    </div>
  );
}

export default Input;
