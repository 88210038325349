import React from "react";
import style from "./Response.module.css";
function Response({ response }) {
  const responseDatas = [
    { header: "Call", content: "/api/v1/test" },
    { header: "Response Code ", content: "/api/v1/test" },
    { header: "Response Headers", content: "/api/v1/test" },
    { header: "Response Body", content: "/api/v1/test" },
  ];

  let responseData = [
    { header: "Call", content: JSON.stringify(response?.request?.responseURL) },
    { header: "Response Code ", content: JSON.stringify(response?.status) },
    { header: "Response Body", content: JSON.stringify(response?.data) },
    { header: "Response Headers", content: JSON.stringify(response?.headers) },
  ];
  return (
    <div className={style.response}>
      {responseData.map((item, index) => (
        <div className={style.card}>
          <h2>{item.header}</h2>
          <p className={style.content}>{item.content}</p>
        </div>
      ))}
    </div>
  );
}

export default Response;
