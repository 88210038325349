import React from "react";
import style from "./About.module.css";
function About({ data }) {
  const { img, title, summary, placeholder, submitImage } = data;
  
  return (
    <div className={style.about}>
      <img src={img} alt="" />
      <h2 className="title">{title}</h2>
      <p className={style.summary}>{summary}</p>
      <div className={style.input}>
        <input
          type="text"
          placeholder={placeholder}
          className={style.inputText}
        />
        <input type="image" src={submitImage} className={style.submit}  />
      </div>
    </div>
  );
}

export default About;
