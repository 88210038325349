import React from "react";
import style from "./Card.module.css";
function Card(props) {
  const { content } = props;
  const header = ["parameter", "value", "Type", "Description"];

  return (
    <div className={style.card}>
      <ul className={style.header}>
        {header.map((item, index) => (
          <li className={style.title} key={index}>
            {item}
          </li>
        ))}
      </ul>

      {content.map((item, index) => (
        <ul className={style.body} key={index}>
          <li className={style.content}>{item.parameter}</li>
          <li className={style.content}>{item.value}</li>
          <li className={style.content}>{item.type}</li>
          <li className={style.content}>{item.description}</li>
        </ul>
      ))}
    </div>
  );
}

export default Card;
