export const header = {
  logo: "/assests/images/logo.png",
  links: [
    {
      name: "SUSCRIBE",
      url: "/#suscribe",
    },
    {
      name: "COMPETITION",
      url: "/",
    },
    {
      name: "FAQ",
      url: "/",
    },
  ],
};

export const about = {
  img: "/assests/images/placard-1.png",
  title: "Play. Learn. Pratice. Excel",
  summary:
    "Help your child excel in any subject from as little as #20! We make learning fun and effortless.",
  placeholder: "Enter your Glo number to Login or Subscribe",
  submitImage: "/assests/images/glo.png",
};

export const hero = {
  image: "/assests/images/hero.png",
};

export const events = {
  title: "What's happening this week",
  options: [
    {
      name: "year",
      options: [
        { value: "2005", label: "2005" },
        { value: "2006", label: "2006" },
        { value: "2007", label: "2007" },
        { value: "2008", label: "2008" },
        { value: "2009", label: "2009" },
        { value: "2010", label: "2010" },
        { value: "2011", label: "2011" },
        { value: "2012", label: "2012" },
        { value: "2013", label: "2013" },
        { value: "2014", label: "2014" },
        { value: "2015", label: "2015" },
        { value: "2016", label: "2016" },
        { value: "2017", label: "2017" },
        { value: "2018", label: "2018" },
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" },
      ],
    },

    {
      name: "Subject",
      options: [
        { value: "math", label: "math" },
        { value: "english", label: "english" },
      ],
    },
    {
      name: "Topic",
      options: [
        {
          value: "pythagoras",
          label: "pythagoras",
        },
        {
          value: "triangle",
          label: "triangle",
        },

        {
          value: "square",
          label: "square",
        },
      ],
    },
  ],
};

export const cards = {
  cards: [
    {
      image: "/assests/images/hero.png",
    },
    {
      image: "/assests/images/skipping-girl.png",
    },
    {
      image: "/assests/images/house-girl.png",
    },
    {
      image: "/assests/images/music-boy.png",
    },
    {
      image: "/assests/images/hero.png",
    },
    {
      image: "/assests/images/skipping-girl.png",
    },
    {
      image: "/assests/images/house-girl.png",
    },
    {
      image: "/assests/images/music-boy.png",
    },
    {
      image: "/assests/images/hero.png",
    },
    {
      image: "/assests/images/skipping-girl.png",
    },
    {
      image: "/assests/images/house-girl.png",
    },
    {
      image: "/assests/images/music-boy.png",
    },
    {
      image: "/assests/images/hero.png",
    },
    {
      image: "/assests/images/skipping-girl.png",
    },
    {
      image: "/assests/images/house-girl.png",
    },
    {
      image: "/assests/images/music-boy.png",
    },
  ],
};
export const subscription = {
  title: "Subscription Plans",
  onSwitch: "One-time",
  offSwitch: "Auto-renew",
  plans: [
    {
      title: "Daily",
      price: "N 200",
    },
    {
      title: "Weekly",
      price: "N 200",
    },
    {
      title: "Monthly",
      price: "N 200",
    },
  ],
};

export const footer = {
  logo: "/assests/images/logo.png",
  links: [
    { title: "Subscribe", url: "/subscribe" },
    { title: "FAQ", url: "" },
    { title: "Privacy", url: "" },
    { title: "Terms", url: "" },
    { title: "Contact", url: "" },
  ],
  socials: [
    {
      image: "/assests/images/facebook.png",
    },
  ],
};

export const testapi = {
  title: "Test Api",
  summary: "This is a test api",
  cards: [{}],
};
