const prod = {
	url: {
		API_URL: `https://afternoon-ridge-35420.herokuapp.com/litelab.9ijakids.com/api/v2`,
	},
};

// added cors proxy for dev environment
const dev = {
	url: {
		API_URL:
			'https://afternoon-ridge-35420.herokuapp.com/http://litelab.9ijakids.com/api/v2',
	},
};

export const Config = process.env.NODE_ENV === 'development' ? dev : prod;
