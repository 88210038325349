import React from "react";
import Card from "../Card/Card";
import Input from "../Input/Input";
import Response from "../Response/Response";
import style from "./Option.module.css";
function Option({ data }) {
  const { title, type, input, submit, documentation, response } = data;
  return (
    <div className={style.option}>
      <div className={style.header}>
        <h2 className={style.title}>{title}</h2>
        <h2 className={type === "Get" ? style.get : style.post}>{type}</h2>
      </div>
      <div className={style.card}>
        <div className={style.apiDetails}>
          {input.map((data, index) => (
            <div className={style.apiDetail} key={index}>
              <Input
                label={data.label}
                value={data.value}
                setValue={data.setter}
              />
            </div>
          ))}
        </div>

        <Card content={documentation} />
        <button className={style.button} onClick={submit}>
          {" "}
          Get Response
        </button>
        {response && (
          <div className={style.response}>
            <Response response={response} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Option;
