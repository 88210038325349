import React from "react";

import style from "./Landing.module.css";
import About from "../../components/About/About";
import * as data from "../../data";
import Hero from "../../components/Hero/Hero";
import Events from "../../components/Events/Events";
import Cards from "../../components/Cards/Cards";
import Subscription from "../../components/Subscription/Subscription";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import CardContainer from "../../components/CardContainer/CardContainer";
function Landing() {
  // const IH =  window.__INITIAL_HEADERS__
  // console.log(IH,"initial headers")
  return (
    <div>
      <Header data={data.header} />
      <About data={data.about} />
<div className={style.Padded}>


      <Hero data={data.hero} />
      <Events data={data.events} />
      <CardContainer data={data.cards} />
      <Subscription data={data.subscription} />
</div>

      <Footer data={data.footer} />
    </div>
  );
}

export default Landing;
