import React from "react";
import style from "./Footer.module.css";
function Footer({ data }) {
  const { logo, links, socials } = data;
  return (
    <div className={style.footer}>
      <div className={style.center}>
        <p className={style.linkTitle}>Useful Information</p>
        {links.map((link, index) => (
          <a href={link.url} key={index} className={style.link}>
            {link.title}
          </a>
        ))}
        <div className={style.right}>
          {socials.map((social, index) => (
            <div className={style.social} key={index}>
              <img src={social.image} alt="" />
            </div>
          ))}
        </div>
      </div>
       <div className={style.left}>
       <img src={logo} alt="" />
     </div>
    </div>
  );
}

export default Footer;
